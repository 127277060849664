import React, { useState } from 'react';
import AppointmentBox from './AppointmentBox';
import { formatDate, formatAddress, formatAppointmentDate, getData } from '../../helper';

import './style.scss';

function AppointmentPanel({
  API,
  patientList,
  appointments,
  waitingRoom,
  search,
  setAppointments,
  setShouldPauseRefresh,
}) {
  const [ searchType, setSearchType ] = useState('date');
  const [ searchYear, setSearchYear ] = useState('');
  const [ searchMonth, setSearchMonth ] = useState('');
  const [ searchDate, setSearchDate ] = useState('');
  const [ patientName, setPatientName ] = useState('');
  const EmptyAppointment = (
    <div className="appointment-empty-box">
      <h2 className="appointment-participant">
        Congrats! No upcoming appointments for today
      </h2>
    </div>
  );
  let AppointmentComp = null;
  if (patientList.length > 0 && appointments.length > 0) {
    AppointmentComp = appointments.map((appointment, idx) => {
      const { patientId, appointmentStartDate, appointmentId, appointmentLink, appointmentType, participants } = appointment;
      const patient = patientList.find(p => p.userId === patientId);
      const appointmentTime = formatDate(appointmentStartDate);
      const patientAddress = formatAddress(
        patient.streetAddressOne,
        patient.streetAddressTwo,
        patient.city,
        patient.state,
        patient.zipCode
      );
      const props = {
        API,
        participants,
        appointmentTime,
        appointmentLink,
        appointmentId,
        appointmentType,
        waitingRoom,
        firstName: patient.firstName,
        lastName: patient.lastName,
        dob: patient.dob,
        email: patient.email,
        phone: patient.mobile,
        address: patientAddress,
      };
      return <AppointmentBox key={`appt-box-${idx}`} {...props} />;
    });
  } else {
    AppointmentComp = EmptyAppointment;
  }

  const getAppointments = async (type, criteria) => {
    const sessionId = localStorage.getItem('sessionId');
    const userId = localStorage.getItem('userId');
    const message = await getData(
      `${API}/appointment/${type}/${criteria}`,
      { 'session-id': sessionId, 'user-id': userId }
    );
    const { status, appointments } = message;
    if (status !== 'ok') return [];
    return appointments;
  };

  const handleInput = (event) => {
    const { target } = event;
    const { name, value } = target;
    const valueSet = {
      year: () => {
        console.log('searchYear.length: ', searchYear.length);
        if (value.length > 4) return;
        setSearchYear(value);
      },
      month: () => {
        if (value.length > 2) return;
        setSearchMonth(value);
      },
      date: () => {
        if (value.length > 2) return;
        setSearchDate(value);
      },
      'patient-name': () => {
        setPatientName(value);
      }
    };
    valueSet[name]();
  };

  const handleKeyUp = async () => {
    if (searchYear || searchMonth || searchDate || patientName) {
      setShouldPauseRefresh(true);
    }
    if (
      searchYear.length === 4
      && searchMonth.length >= 1
      && searchDate >= 1
    ) {
      const timeQuery = formatAppointmentDate(
        `${searchYear}/${searchMonth}/${searchDate}`,
        `${searchYear}/${searchMonth}/${searchDate}`
      );
      const appointments = await getAppointments('date', timeQuery);
      setAppointments(appointments);
    }
    if (patientName) {
      const foundPatients = patientList.filter(patient => {
        const { firstName, lastName } = patient;
        const name = `${firstName} ${lastName}`;
        const nameMatch = new RegExp(patientName, 'i');
        return nameMatch.test(name);
      });
      let aggregatedAppointments = [];
      foundPatients.forEach(async patient => {
        const { userId } = patient;
        const appointments = await getAppointments('patient', userId);
        aggregatedAppointments = aggregatedAppointments.concat(appointments);
        setAppointments(aggregatedAppointments);
      });
    }
    if (!searchYear && !searchMonth && !searchDate && !patientName) {
      const timeQuery = formatAppointmentDate();
      const appointments = await getAppointments('date', timeQuery);
      setAppointments(appointments);
      setShouldPauseRefresh(false);
    }
  };

  const clearSearch = async () => {
    setSearchYear('');
    setSearchMonth('');
    setSearchDate('');
    setPatientName('');
    const timeQuery = formatAppointmentDate();
    const appointments = await getAppointments('date', timeQuery);
    setAppointments(appointments);
    setShouldPauseRefresh(false);
  };

  return (
    <>
      <div className="appointment-header">
        <h2 className="appointment-header-title">Appointments</h2>
      </div>
      {search && 
        <div className="appointment-search-box">
          <div className="appointment-search-input-group">
            { searchType === 'date' &&
              <>
                <div className="appointment-search-input-wrapper">
                  <p className="appointment-search-input-label">Year</p>
                  <input
                    className="appointment-search-input"
                    type="number"
                    name="year"
                    placeholder={new Date().getFullYear()}
                    value={searchYear}
                    onChange={handleInput}
                    onKeyUp={handleKeyUp}
                  />
                </div>
                <div className="appointment-search-input-wrapper">
                  <p className="appointment-search-input-label">Month</p>
                  <input
                    className="appointment-search-input"
                    type="number"
                    name="month"
                    placeholder={new Date().getMonth() + 1}
                    value={searchMonth}
                    onChange={handleInput}
                    onKeyUp={handleKeyUp}
                  />
                </div>
                <div className="appointment-search-input-wrapper">
                  <p className="appointment-search-input-label">Date</p>
                  <input
                    className="appointment-search-input"
                    type="number"
                    name="date"
                    placeholder={new Date().getDate()}
                    value={searchDate}
                    onChange={handleInput}
                    onKeyUp={handleKeyUp}
                  />
                </div>
              </>
            }
            { searchType === 'patient-name' &&
              <div className="appointment-search-input-wrapper">
                <p className="appointment-search-input-label">Patient Name</p>
                <input
                  className="appointment-search-input"
                  type="text"
                  name="patient-name"
                  placeholder={`First Name & Last Name`}
                  value={patientName}
                  onChange={handleInput}
                  onKeyUp={handleKeyUp}
                />
              </div>
            }
          </div>
          <button
            className="appointment-input-clear-button"
            onClick={clearSearch}
          >
            Clear Search
          </button>
          <div className="appointment-search-button-group">
            <button
              className={`
                appointment-search-type-button
                ${searchType === 'date' ? 'appointment-search-type-button-selected' : ''}
              `}
              onClick={() => setSearchType('date')}
            >
              Date
            </button>
            <button
              className={`
                appointment-search-type-button
                ${searchType === 'patient-name' ? 'appointment-search-type-button-selected' : ''}
              `}
              onClick={() => setSearchType('patient-name')}
            >
              Patient Name
            </button>
          </div>
        </div>
      }
      <div className="appointment-wrapper">
        { AppointmentComp }
      </div>
    </>
  );
}

export default AppointmentPanel;