import React, { useState } from 'react';
import { searchPatients } from '../../hooks';

import './style.scss';

function PatientSearchBar({ patientList, setFoundPatients }) {
  const [ patientName, setPatientName ] = useState('');
  searchPatients(patientList, patientName, setFoundPatients);

  const handlePatientSearch = event => {
    setPatientName(event.target.value);
  };

  const clearSearch = (event) => {
    if (event.key === 'Backspace' && patientName === '') {
      setFoundPatients([]);
    }
  };

  return (
    <section className="patient-search-bar">
      <input
        className="patient-search-input"
        type="text"
        placeholder="Patient's First and Last Name"
        value={patientName}
        onChange={handlePatientSearch}
        onKeyUp={clearSearch}
      />
    </section>
  );
}

export default PatientSearchBar;
