import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { postData, getData } from '../../helper';
import { getAppointments, navigateOnly } from '../../hooks';
import en from '../../locale/en';

import './style.scss';

const createCalendar = (
  month=new Date().getMonth(),
  year=new Date().getFullYear(),
) => {
  if (month === -1) {
    month = 11;
    year = year - 1;
  }
  if (month === 12) {
    month = 0;
    year = year + 1;
  }
  let date = new Date(year, month, 1);
  let calendarDays = [];
  while (date.getMonth() === month) {
    calendarDays.push(new Date(date));
    date.setDate(date.getDate() + 1);
  }
  return calendarDays;
}

const makeCalendarComp = (calendarDays, currentDate, setCurrentDate) => {
  let CalendarDateComp = [];
  let SevenDaysComp = [];
  let datePlaceHolder = (key='1') => (
    <div
      className="date-btn-placeholder"
      key={`date-btn-placeholder-${key}`}
    />
  );
  calendarDays.forEach((d, idx) => {
    // Align first day to the week day
    if (idx === 0) {
      const firstDay = d.getDay();
      for (let days = 0; days < firstDay; days = days + 1) {
        SevenDaysComp.push(datePlaceHolder(days));
      }
    }
    const DayButtonComp = (
      <button
        onClick={() => setCurrentDate(d.getDate())}
        className={`date-btn ${d.getDate() === currentDate ? 'date-btn-active' : ''}`}
        name="select-date"
        key={`day-btn-${idx}`}
      >
        {`${JSON.stringify(d.getDate())}`}
      </button>
    );
    SevenDaysComp.push(DayButtonComp);
    // Wrap every 7 buttons in a container
    if (SevenDaysComp.length % 7 !== 0 && idx === (calendarDays.length - 1)) {
      const daysToFill = 7 - SevenDaysComp.length;
      for (let days = 0; days < daysToFill; days = days + 1) {
        SevenDaysComp.push(datePlaceHolder(days));
      }
    }
    if (SevenDaysComp.length % 7 === 0) {
      CalendarDateComp.push(
        <div
          className="date-container"
          key={`date-container-${idx}`}
        >{ SevenDaysComp }</div>
      );
      SevenDaysComp = [];
    }
  });
  return CalendarDateComp;
}

const makeTimeComp = (start=8, end=18, currentTime, setCurrentTime, appointmentOnDate) => {
  const existingAppointmentTime = appointmentOnDate.map(appt => {
    const apptDate = new Date(appt.appointmentStartDate);
    const apptHours = apptDate.getHours();
    const apptMinutes = apptDate.getMinutes();
    return parseFloat(`${apptHours}${apptMinutes === 30 ? '.5' : ''}`);
  });
  const TimeComp = {
    Morning: [],
    Afternoon: []
  };
  for (let t = start; t <= end; t = t + 0.5) {
    if (existingAppointmentTime.indexOf(t) === -1) {
      if (t < 12) {
        const morningTime = `${Math.trunc(t)}:${t % 1 === 0 ? `00` : `30`} AM`;
        TimeComp.Morning.push(
          <button
            onClick={() => setCurrentTime(morningTime)}
            className={
              `select-time-btn ${currentTime === morningTime ? 'select-time-btn-active' : ''}`
            }
            key={`select-time-btn-${t}`}
          >
            {morningTime}
          </button>
        );
      } else {
        const afternoonTime = `${Math.trunc(t > 12.5 ? t - 12 : t)}:${t % 1 === 0 ? `00` : `30`} PM`;
        TimeComp.Afternoon.push(
          <button
            onClick={() => setCurrentTime(afternoonTime)}
            className={
              `select-time-btn ${currentTime === afternoonTime ? 'select-time-btn-active' : ''}`
            }
            key={`select-time-btn-${t}`}
          >
            { afternoonTime }
          </button>
        );
      }
    }
  }
  return TimeComp;
};

function Calendar({ API, provider }) {
  const location = useLocation();
  const navigate = useNavigate();
  const { state } = location;
  navigateOnly('/my-patients', state === null);
  navigateOnly('/', provider === null, '/calendar');
  const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  const todaysDate = new Date();
  const [ appointmentType, setAppointmentType ] = useState('Select service type');
  const [ currentMonth, setCurrentMonth ] = useState(todaysDate.getMonth());
  const [ currentYear, setCurrentYear ] = useState(todaysDate.getFullYear());
  const [ currentDate, setCurrentDate ] = useState(todaysDate.getDate());
  const [ currentTime, setCurrentTime ] = useState('Select a time');
  const [ appointmentOnDate, setAppointmentOnDate ] = useState([]);
  const noneIndexedMonth = currentMonth + 1;
  const formatedDate = `${noneIndexedMonth}/${currentDate}/${currentYear}`;
  const calendarDays = createCalendar(currentMonth, currentYear);
  const CalendarComp = makeCalendarComp(calendarDays, currentDate, setCurrentDate);
  const currentMinute = todaysDate.getMinutes();
  let timeStart = 9;
  if (
    todaysDate.getDate() === currentDate
    && todaysDate.getMonth() === currentMonth
    && todaysDate.getFullYear() === currentYear
  ) {
    timeStart = todaysDate.getHours();
    if (currentMinute > 0 && currentMinute < 30) {
      timeStart = timeStart + 0.5;
    }
    if (currentMinute > 30) {
      timeStart = timeStart + 1;
    }
  }
  const appointmentStartDate = new Date(`${currentYear}/${currentMonth + 1}/${currentDate}`);
  const appointmentEndDate = new Date(`${currentYear}/${currentMonth + 1}/${currentDate}`);
  appointmentStartDate.setHours(0);
  appointmentEndDate.setHours(23);
  getAppointments(API, 'date', `${appointmentStartDate.getTime()}-${appointmentEndDate.getTime()}`, setAppointmentOnDate);
  let TimeComp = makeTimeComp(timeStart, 17, currentTime, setCurrentTime, appointmentOnDate);

  const incrementMonth = () => {
    if (currentMonth >= 11) {
      setCurrentMonth(0);
      setCurrentYear(currentYear + 1);
      return;
    }
    setCurrentMonth(currentMonth + 1);
  };

  const decrementMonth = () => {
    if (currentMonth <= 0) {
      setCurrentMonth(11);
      setCurrentYear(currentYear - 1)
      return;
    }
    setCurrentMonth(currentMonth - 1);
  };

  const scheduleAppointment = () => {
    if (
      appointmentType === 'Select service type'
      || currentTime === 'Select a time'
    ) {
      return;
    }
    const sessionId = localStorage.getItem('sessionId');
    const appointmentDate = new Date(`${formatedDate} ${currentTime}`);
    const appointmentStartDate = new Date(new Date(appointmentDate).toUTCString()).toISOString();
    const appointmentEndDate = new Date(new Date(appointmentDate.getTime() + 1800000).toUTCString()).toISOString();
    const appointmentData = {
      appointmentStartDate,
      appointmentEndDate,
      appointmentType,
      patientId: state.patientId,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
    postData(`${API}/appointment`, appointmentData, {
      'session-id': sessionId,
      'user-id': provider.userId,
    })
      .then(message => {
        const { status } = message;
        if (status !== 'ok') {
          navigate(
            '/error',
            { 
              state: {
                statusMessage: en.scheduleAppointment.scheduleErrorMessage,
                statusButton: en.scheduleAppointment.scheduleErrorButtton,
                buttonRoute: '/overview',
                routeFrom: '/invite-patient',
              },
            }
          );
          return;
        }
        navigate(
          '/success',
          {
            state: {
              statusMessage: en.scheduleAppointment.scheduleSuccessMessage,
              statusButton: en.scheduleAppointment.scheduleSuccessButtton,
              buttonRoute: '/overview',
              routeFrom: '/invite-patient', 
            },
          }
        );
      });
  };

  return (
    <section className="calendar-container">
      <div className="calendar-header">
        <h2 className="calendar-header-title">
          {`Schedule New Appointment with ${state.patientName}`}
        </h2>
      </div>
      <Link className="calendar-back-btn" to="/overview">
        Back to Dashboard
      </Link>
      <div className="calendar-wrapper">
        {/* Schedule Type Buttons */}
        <div className="service-type-wrapper">
          <h2 className="service-type-header-title">Sevice Type</h2>
          <button
            onClick={() => setAppointmentType('Initial Visit')}
            className={
              `service-type-btn ${appointmentType === 'Initial Visit' ?
              'service-type-btn-active' : ''}`
            }
          >
            Initial Visit - 30 Minutes
          </button>
          <button
            onClick={() => setAppointmentType('Follow Up')}
            className={
              `service-type-btn ${appointmentType === 'Follow Up' ?
              'service-type-btn-active' : ''}`
            }
          >
            Follow Up - 20 Minutes
          </button>
        </div>
        {/* Calendar */}
        <div className="date-wrapper">
          <h2 className="date-header-title">Date</h2>
          <div className="date-subheader-wrapper">
            <p className="date-subheader-title">
              {`${monthNames[currentMonth]}, ${currentYear}`}
            </p>
            <div className="date-subheader-btn-wrapper">
              <button
                className="date-subheader-btn"
                onClick={decrementMonth}
              >Previous</button>
              <button
                className="date-subheader-btn"
                onClick={incrementMonth}
              >Next</button>
            </div>
          </div>
          <div className="days-of-week-wrapper">
            <p className="days-of-week">Sun</p>
            <p className="days-of-week">Mon</p>
            <p className="days-of-week">Tue</p>
            <p className="days-of-week">Wed</p>
            <p className="days-of-week">Thu</p>
            <p className="days-of-week">Fri</p>
            <p className="days-of-week">Sat</p>
          </div>
          { CalendarComp }
        </div>
        {/* Time Buttons */}
        <div className="time-wrapper">
          <h2 className="time-header-title">Time</h2>
          <div className="part-of-day">
            <p className="part-of-day-header">Morning</p>
            <div className="time-morning">
              {TimeComp && TimeComp.Morning}
            </div>
            <p className="part-of-day-header">Afternoon</p>
            <div className="time-afternoon">
              {TimeComp && TimeComp.Afternoon}
            </div>
          </div>
        </div>
        {/* Create Appointment Buttons */}
        <div className="create-appointment-wrapper">
          <h2 className="create-appointment-header-title">Appointment</h2>
          <p className="create-appointment-type">{ appointmentType }</p>
          <p className="create-appointment-time">{ formatedDate }</p>
          <p className="create-appointment-time">{ currentTime }</p>
          <button
            className="create-appointment-btn"
            onClick={scheduleAppointment}
          >Schedule</button>
        </div>
      </div>
    </section>
  )
}

export default Calendar;
