export class User {
  constructor(user) {
    this.userId = user.userId;
    this.firstName = user.firstName;
    this.lastName = user.lastName;
    this.mobile = user.mobile;
    this.email = user.email;
    this.dob = user.dob
    this.streetAddressOne = user.streetAddressOne;
    this.streetAddressTwo = user.streetAddressTwo;
    this.city = user.city;
    this.state = user.state;
    this.zipCode = user.zipCode;
    this.role = user.role;
    this.verified = user.verified;
    if (user.role === 'provider') {
      this.patientIds = user.patientIds;
      this.npi = user.npi;
    }
    if (user.role === 'patient') {
      this.providerId = user.providerId;
    }
    this.org = user.org;
    this.entryDate = this.entryDate;
  }
}

export class Invite {
  constructor(providerId, patientMobile) {
    this.admin = providerId;
    this.mobile = patientMobile;
    this.role = 'patient';
    this.name = '';
    this.email = '';
  }
}
