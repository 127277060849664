import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";

import './style.scss';

function PatientBox({
  patientId='',
  firstName='First Name',
  lastName='Last Name',
  setExpandPatientInfo,
}) {
  const navigate = useNavigate();
  const handleScheduling = () => {
    navigate('/calendar', {
      state: {
        patientId,
        patientName: `${firstName} ${lastName}`,
      },
    });
  };
  return (
    <section className="patient-info-box">
      <div className="patient-info-header">
        <h2 className="patient-name">
          { `${firstName} ${lastName}` }
        </h2>
        <button
          onClick={() => setExpandPatientInfo(patientId)}
          className="patient-info-btn"
        >
          Patients Info
        </button>
      </div>
      <section className="patient-action-btn-wrapper">
        <button
          className="schedule-appointment-btn"
          onClick={handleScheduling}
        >
          Schedule Appointment
        </button>
      </section>
    </section>
  );
}

export default PatientBox;
