import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { validateEmail, getData, deleteData, putData } from '../../helper';
import en from '../../locale/en';

import './style.scss';

function AppointmentBox({
  API,
  participants,
  waitingRoom,
  appointmentType,
  firstName='',
  lastName='',
  dob='',
  email='',
  phone='',
  address='',
  appointmentTime='',
  appointmentLink='',
  appointmentId='',
}) {
  const navigate = useNavigate();
  const [ participant, setParticipants ] = useState('');
  let WaitingRoomComp = null;
  const roomName = appointmentLink.split('/').slice(-1)[0];
  const currentParticipants = waitingRoom[roomName];
  let ParticipantsComp = null;
  const cancelAppointment = () => {
    const sessionId = localStorage.getItem('sessionId');
    const userId = localStorage.getItem('userId');
    deleteData(`${API}/appointment/${appointmentId}/${roomName}`, {
      'session-id': sessionId,
      'user-id': userId,
    })
      .then(message => {
        const { status } = message;
        if (status !== 'ok') {
          navigate(
            '/error',
            { 
              state: {
                statusMessage: en.appointmentBox.cancelError,
                statusButton: en.appointmentBox.cancelErrorButton,
                buttonRoute: '/overview',
                routeFrom: '/invite-patient',
              },
            }
          );
          return;
        }
        navigate(
          '/success',
          {
            state: {
              statusMessage: en.appointmentBox.cancelSuccess,
              statusButton: en.appointmentBox.cancelSuccessButton,
              buttonRoute: '/overview',
              routeFrom: '/invite-patient', 
            },
          }
        );
      });
  };
  const addParticipant = () => {
    if (validateEmail(participant) === null) return;
    const sessionId = localStorage.getItem('sessionId');
    const userId = localStorage.getItem('userId');
    putData(
      `${API}/appointment/${appointmentId}`,
      { participant },
      { 'session-id': sessionId, 'user-id': userId }
    )
      .then(message => {
        const { status } = message;
        if (status !== 'ok') return;
        setParticipants('');
      });
  };
  const removeParticipant = (email) => {
    console.log('email: ', email);
    const sessionId = localStorage.getItem('sessionId');
    const userId = localStorage.getItem('userId');
    putData(
      `${API}/appointment/${appointmentId}`,
      { deleteParticipant: email },
      { 'session-id': sessionId, 'user-id': userId }
    )
      .then(message => {
        const { status } = message;
        if (status !== 'ok') return;
      });
  };
  const sendReminder = () => {
    const sessionId = localStorage.getItem('sessionId');
    const userId = localStorage.getItem('userId');
    getData(
      `${API}/reminder/${appointmentId}`,
      { 'session-id': sessionId, 'user-id': userId }
    )
      .then(message => {
        console.log('message: ', message);
        const { status } = message;
        if (status !== 'ok') return;
      });
  }
  if (Array.isArray(participants)) {
    ParticipantsComp = participants.map((p, idx) => (
      <div
        className="appointment-participant-wrapper"
        key={`appointment-participant-text-${idx}`}
      >
        <p className="appointment-participant-text">{p}</p>
        <button
          className="remove-participant-button"
          onClick={() => removeParticipant(p)}
        >
          Delete
        </button>
      </div>
    ));
  }
  if (Array.isArray(currentParticipants)) {
    WaitingRoomComp = currentParticipants.map((p, idx) => (
      <p
        className="appointment-waiting-room-names"
        key={`appointment-waiting-room-names-${idx}`}
      >
        {p.userName}
      </p>
    ));
  }
  return (
    <section className="appointment-box">
      <div className="appointment-box-left">
        <div className="appointment-box-header">
          <h2 className="appointment-participant">
            { `${firstName} ${lastName}` }
          </h2>
          <h2 className="appointment-time">
            { appointmentTime }
          </h2>
        </div>
        <div className="participant-contact-wrapper">
          <p className="participant-contact-type">Date of Birth</p>
          <p className="participant-info">{ dob }</p>
        </div>
        <div className="participant-contact-wrapper">
          <p className="participant-contact-type">Email</p>
          <p className="participant-info">{ email }</p>
        </div>
        <div className="participant-contact-wrapper">
          <p className="participant-contact-type">Phone</p>
          <p className="participant-info">{ phone }</p>
        </div>
        <div className="participant-contact-wrapper participant-contact-last-wrapper">
          <p className="participant-contact-type">Address</p>
          <p className="participant-info">{ address }</p>
        </div>
        <div className="appointment-buttons-wrapper">
          <a
            className="appointment-start-button"
            target="_blank"
            rel="noopener noreferrer"
            href={appointmentLink}
          >
            Start Session
          </a>
          <button
            className="appointment-reminder-button"
            onClick={sendReminder}
          >
            Send Reminder
          </button>
          <button
            className="appointment-cancel-button"
            onClick={cancelAppointment}
          >
            Cancel
          </button>
        </div>
      </div>
      <div className="appointment-box-right">
        <h2 className="appointment-type">
          {`Appointment Type: ${appointmentType}`}
        </h2>
        <h2 className="appointment-box-right-title">
          Additional Participants
        </h2>
        { ParticipantsComp }
        <div className="appointment-add-participant">
          <input
            className="appointment-add-participant-input"
            type="text"
            name="add-participant"
            placeholder={`example@advancevitals.com`}
            value={participant}
            onChange={event => setParticipants(event.target.value)}
          />
          <button
            className="appointment-add-participant-button"
            onClick={addParticipant}
          >
            Add
          </button>
        </div>
        <h2 className="appointment-box-right-title">
          Participants In Waiting Room
        </h2>
        <div className="appointment-waiting-room">
          { WaitingRoomComp }
        </div>
     </div>
    </section>
  );
}

export default AppointmentBox;
