import React, { useState, useEffect } from 'react';
import { Routes, Route } from "react-router-dom";
import GateForEntry from './pages/GateForEntry';
import Dashboard from './pages/Dashboard';
import Calendar from './pages/Calendar';
import InvitePatients from './pages/InvitePatients';
import VideoCall from './pages/VideoCall';
import OpStatus from './pages/OpStatus';

// Style import
import './style/reset.scss';
import './style/style.scss';

const API = 'https://api.advancevitals.com';
// const API = 'http://localhost:3000';

export function App() {
  const [ provider, setProvider ] = useState(null);
  const props = { API, provider, setProvider };

  return (
    <div className="advance-vital-app">
      <Routes>
        <Route exact path="/" element={ <GateForEntry {...props} /> } />
        <Route exact path="/overview" element={ <Dashboard {...props} /> } />
        <Route exact path="/appointments" element={ <Dashboard {...props} /> } />
        <Route exact path="/my-patients" element={ <Dashboard {...props} /> } />
        <Route exact path="/messages" element={ <Dashboard {...props} /> }/>
        <Route exact path="/calendar" element={ <Calendar {...props} /> }/>
        <Route exact path="/video-call" element={<VideoCall {...props} />} />
        <Route exact path="/invite-patient" element={ <InvitePatients {...props} /> } />
        <Route exact path="/contact-support" element={<Dashboard />} />
        <Route exact path="/success" element={<OpStatus {...props} />} />
        <Route exact path="/error" element={<OpStatus {...props} />} />
      </Routes>
    </div>
  );
}
