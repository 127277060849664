import React, { useState, useRef, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import SidePanel from '../../components/SidePanel';
import Overview from '../../components/Overview';
import PatientsPanel from '../../components/PatientsPanel';
import AppointmentPanel from '../../components/AppointmentPanel';
import MessagePanel from '../../components/MessagePanel';
import {
  navigateOnly,
  getPatientList,
  getAppointments,
  getParticipants,
} from '../../hooks';
import { formatAppointmentDate } from '../../helper';

import './style.scss';

function Dashboard({ API, provider, messageList }) {
  const location = useLocation();
  const [ patientList, setPatientList ] = useState([]);
  const [ appointments, setAppointments ] = useState([]);
  const [ waitingRoom, setWaitingRoom ] = useState({});
  const [ shouldRefresh, setShouldRefresh ] = useState(true);
  const [ shouldPauseRefresh, setShouldPauseRefresh ] = useState(false);
  const refreshTimerId = useRef(null);
  const { pathname } = location;
  navigateOnly('/', provider === null, pathname);

  if (provider) {
    const timeQuery = formatAppointmentDate();
    getPatientList(API, setPatientList, shouldRefresh);
    getAppointments(API, 'date', timeQuery, setAppointments);
    getParticipants(API, setWaitingRoom, shouldRefresh);
  }

  useEffect(() => {
    if (shouldPauseRefresh === true) return;
    refreshTimerId.current = setTimeout(() => {
      setShouldRefresh(!shouldRefresh);
    }, 1500);
    return function reset() {
      clearTimeout(refreshTimerId.current);
    }
  }, [ shouldRefresh, shouldPauseRefresh, setShouldRefresh, refreshTimerId ]);

  const PanelComponent = {
    '/overview': (
      <>
        <Overview
          appointments={appointments}
          patientList={patientList}
          messageList={messageList}
        />
        <AppointmentPanel
          API={API}
          appointments={appointments.slice(0, 2)}
          patientList={patientList}
          waitingRoom={waitingRoom}
          search={false}
          setAppointments={setAppointments}
          setShouldPauseRefresh={setShouldPauseRefresh}
        />
      </>
    ),
    '/appointments': (
      <AppointmentPanel
        API={API}
        appointments={appointments}
        patientList={patientList}
        waitingRoom={waitingRoom}
        search={true}
        setAppointments={setAppointments}
        setShouldPauseRefresh={setShouldPauseRefresh}
      />
    ),
    '/my-patients': (
      <PatientsPanel
        patientList={patientList}
      />
    ),
    '/messages': (
      <MessagePanel />
    ),
  };

  return (
    <section className="dashboard-container">
      { provider && <SidePanel provider={provider} /> }
      <div className="dashboard-panel">
        { PanelComponent[pathname] }
      </div>
    </section>
  )
}

export default Dashboard;
