export default {
  gateForEntry: {
    requestValidation: `Enter email access beta, if you haven't already signed up, please email hello@advancevitals.com to request access`,
    enterValidation: `An access code has been sent to your via email and text`,
  },
  invitePatients: {
    title: `Invite New Patient`,
    backButton: `Back to Dashboard`,
    addPatientButton: `Add Patient`,
    inviteButton: `Send Invite`,
    inviteSuccessMessage: `You've successfully sent an invite. They'll receive a text message with a link to sign up.`,
    inviteSuccessButtton: `Back to Dashboard`,
    inviteErrorMessage: `An error has occured. Please make sure you enter a valid phone number and check your internet connection`,
    inviteErrorButtton: `Back to Dashboard`,
  },
  scheduleAppointment: {
    scheduleSuccessMessage: `You've successfully scheduled the appointment. They'll receive a text message with the appointment details.`,
    scheduleSuccessButtton: `Back to Dashboard`,
    scheduleErrorMessage: `An error has occured. Please make sure you are not scheduling duplicate appointments`,
    scheduleErrorButtton: `Back to Dashboard`,
  },
  appointmentBox: {
    cancelSuccess: `You've successfully canceled the appointment. `,
    cancelSuccessButton: `Back to Dashboard`,
    cancelError: `An error has occured. Please try again.`,
    cancelErrorButton: `Back to Dashboard`,
  },
}
