import React from 'react';
import { useLocation, useNavigate } from "react-router-dom";

import './style.scss';

function OpStatus() {
  const location = useLocation();
  const navigate = useNavigate();
  const { state, pathname } = location; 
  const title = pathname === '/success' ? 'Success' : 'Error';
  let statusMessage = 'This is status message';
  let statusButton = 'Status Button';
  let buttonRoute = '/';
  let routeFrom = '/';
  if (typeof state === 'object') {
    statusMessage = state.statusMessage ? state.statusMessage : statusMessage;
    statusButton = state.statusButton ? state.statusButton : statusButton;
    buttonRoute = state.buttonRoute ? state.buttonRoute : buttonRoute;
    routeFrom = state.routeFrom ? state.routeFrom : routeFrom;
  }
  const handleTryAgainButton = () => {
    navigate(routeFrom);
  };
  const handleStatusButton = () => {
    navigate(buttonRoute);
  };
  return (
    <section className="status">
      <div className="status-wrapper">
        <h1 className="status-title">{ title }</h1>
        <p className="status-message">{ statusMessage }</p>
        <div className="status-btn-group">
          { pathname === '/error' &&
            <button
              className="status-btn"
              onClick={handleTryAgainButton}
            >
              Try Again
            </button>
          }
          <button
            className="status-btn"
            onClick={handleStatusButton}
          >
            { statusButton }
          </button>
        </div>
      </div>
    </section>
  );
}

export default OpStatus;
