import React from 'react';
import { formatDate } from '../../helper';
import './style.scss';

function MessageRequestAppointment({ content, patient }) {
  const DateButton = content.map((time, idx) => (
    <button
      key={`message-button-${idx}`}
      className="message-button"
    >
      { formatDate(new Date(time)) }
    </button>
  ));
  return (
    <div className="message-content-wrapper">
      <p className="message-content">
        Requested an appointment, select a time that works for you:
      </p>
      <div className="message-button-wrapper">
        { DateButton }
      </div>
    </div>
  );
}

function MessageCanceledAppointment({ content, patient }) {
  return (
    <div className="message-content-wrapper">
      <p className="message-content">
        Canceled an appointment on 
        <span className="message-content-highlight">
          {` ${formatDate(new Date(content))}`}
        </span>
      </p>
      <p className="message-content">
        {`Phone: ${patient.phoneNumber}`}
      </p>
      <p className="message-content last-message-content">
        {`Email: ${patient.email}`}
      </p>
    </div>
  );
}

function MessageCompletedSignUp() {
  return (
    <div className="message-content-wrapper">
      <p className="message-content last-message-content">
        Completed sign up
      </p>
    </div>
  );
}

function MessageRescheduleAppointment({ content, patient }) {
  const DateButton = content.map((time, idx) => (
    <button
      key={`message-button-${idx}`}
      className="message-button"
    >
      { formatDate(new Date(time)) }
    </button>
  ));
  return (
    <div className="message-content-wrapper">
      <p className="message-content">
        Rescheduled the appointment, select a time that works fo you:
      </p>
      <div className="message-button-wrapper">
        { DateButton }
      </div>
    </div>
  );
}

function Messages() {
  // const props = { content, patient };
  // const MessageComp = {
  //   'request-appointment': <MessageRequestAppointment {...props} />,
  //   'cancel-appointment': <MessageCanceledAppointment {...props} />,
  //   'complete-signup': <MessageCompletedSignUp {...props} />,
  //   'reschedule-appointment': <MessageRescheduleAppointment {...props} />,
  // };
  // const formatedTimeStamp = formatDate(timeStamp);
  return (
    <section className='message-content-container'>
      <div className="message-request-left">
        <div className="message-sender-wrapper">
          <p className="message-sender">
            {/* { `${patient.firstName} ${patient.lastName}` } */}
          </p>
          <button
            className="patient-info-button"
            onClick={() => {}}
          >Patient Info</button>
        </div>
        {/* { MessageComp[type] } */}
      </div>
      <div className="message-request-right">
        {/* <p className="message-time">{ formatedTimeStamp }</p> */}
        <button className="message-read-button">Mark Read</button>
      </div>
    </section>
  );
}

export default Messages;
