import React from 'react';

function PatientInfo({
  dob='',
  email='',
  phone='',
  address='',
}) {
  return (
    <section className="patient-info-container">
      <div className="patient-contact-wrapper">
        <p className="patient-contact-type">Date of Birth</p>
        <p className="patient-info-text">{ dob }</p>
      </div>
      <div className="patient-contact-wrapper">
        <p className="patient-contact-type">Email</p>
        <p className="patient-info-text">{ email }</p>
      </div>
      <div className="patient-contact-wrapper">
        <p className="patient-contact-type">Phone</p>
        <p className="patient-info-text">{ phone }</p>
      </div>
      <div className="patient-contact-wrapper">
        <p className="patient-contact-type">Address</p>
        <p className="patient-info-text">{ address }</p>
      </div>
    </section>
  );
}

export default PatientInfo;
