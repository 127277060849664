import React, { useState } from 'react';
import { useLocation } from "react-router-dom";
import { postData } from '../../helper';
import { User } from '../../models';
import { getProviderData, navigateOnly } from '../../hooks';
import en from '../../locale/en';

import './style.scss';

function GateForEntry({ API, provider, setProvider }) {
  const [ email, setEmail ] = useState('');
  const [ requestSuccess, setRequestSuccess ] = useState(false);
  const [ validationCode, setValidationCode ] = useState('');
  const location = useLocation();
  let routeAfterValidation = '/overview';
  if (location.state && location.state.routeFrom) {
    routeAfterValidation = location.state.routeFrom;
  }

  getProviderData(API, (providerData) => setProvider(providerData));

  navigateOnly(routeAfterValidation, provider !== null);

  const handleValidationRequest = () => {
    postData(`${API}/signin`, { email })
      .then(message => {
        const { status } = message;
        if (status !== 'ok') {
          setRequestSuccess(false);
          return;
        }
        setRequestSuccess(true);
      });
  };

  const handleValidation = () => {
    postData(`${API}/validate`, { email, validationCode })
      .then(message => {
        const { status, user, session } = message;
        if (status !== 'ok') {
          return;
        }
        const provider = new User(user);
        setProvider(provider);
        localStorage.setItem('sessionId', session.sessionId);
        localStorage.setItem('userId', user.userId);
      });
  };

  return (
    <section className="entry">
      <div className="entry-input-wrapper">
        <h1 className="entry-title">Advance Vitals</h1>
        { requestSuccess === false &&
          <>
            <label className="entry-input-label" htmlFor="gate-password">
              { en.gateForEntry.requestValidation }
            </label>
            <input
              className="entry-input-field"
              type="text"
              value={email}
              name="entry-access-code"
              id="gate-access-code"
              placeholder="jane@example.com"
              onChange={(value) => setEmail(value.target.value)}
            />
            <button
              className="entry-button"
              onClick={handleValidationRequest}
            >
              Next
            </button>
          </>
        }
        { requestSuccess === true &&
          <>
            <label className="entry-input-label" htmlFor="gate-password">
              { en.gateForEntry.enterValidation }
            </label>
            <input
              className="entry-input-field"
              type="text"
              value={validationCode}
              name="entry-access-code"
              id="gate-access-code"
              placeholder="12345"
              onChange={(value) => setValidationCode(value.target.value)}
            />
            <button
              className="entry-button"
              onClick={handleValidation}
            >
              Go to Dashboard
            </button>
            <button
              className="entry-email-button"
              onClick={() => setRequestSuccess(false)}
            >
              Back to Email
            </button>
          </>
        }
      </div>
    </section>
  );
}

export default GateForEntry;
