import React from 'react';
import { Link, useLocation } from "react-router-dom";

import './style.scss';

function SidebarMainButton({ name="Main Button", routePath="/" }) {
  const { pathname } = useLocation();
  return (
    <Link
      className={`
        sidebar-main-button
        ${routePath === pathname ? 'sidebar-main-button-active' : ''}
      `}
      to={routePath}
    >
      { name }
    </Link>
  );
}

function SidebarSecondaryButton({ name="Secondary Button", routePath="/" }) {
  const { pathname } = useLocation();
  return (
    <Link
      className={`
        sidebar-secondary-button
        ${routePath === pathname ? 'sidebar-secondary-button-active' : ''}
      `}
      to={routePath}
    >
      { name }
    </Link>
  );
}

function SidePanel({ provider }) {
  const { firstName, lastName } = provider;
  return (
    <section className="sidebar">
      <div className="sidebar-header-wrapper">
        <h1 className="sidebar-title">Advance Vitals</h1>
        <div className="provider-header">
          <div className="provider-header-left">
            <p className="provider-header-left-text">
              {`${firstName[0]}${lastName[0]}`}
            </p>
          </div>
          <div className="provider-header-right">
            <p className="provider-header-right-text">
              {`${firstName} ${lastName}`}
            </p>
          </div>
        </div>
      </div>
      <div className="sidebar-main-button-wrapper">
        <SidebarMainButton name="Overview" routePath="/overview" />
        <SidebarMainButton name="Appointments" routePath="/appointments" />
        <SidebarMainButton name="My Patients" routePath="/my-patients" />
        {/* <SidebarMainButton name="Messages" routePath="/messages" /> */}
      </div>
      <div className="sidebar-secondary-button-wrapper">
        <SidebarSecondaryButton
          name="Invite Patient"
          routePath="/invite-patient"
        />
        <SidebarSecondaryButton
          name="Contact Support"
          routePath="/contact-support"
        />
      </div>
    </section>
  );
}

export default SidePanel;
