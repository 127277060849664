import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from "react-router-dom";
import { getData, postData } from "../helper";
import { User } from "../models";

export const navigateOnly = (routeTo, isTrue, routeParam) => {
  const navigate = useNavigate();
  useEffect(() => {
    if (!isTrue) {
      return;
    }
    navigate(routeTo, { state: routeParam ? { ...routeParam } : null });
  });
};

export const getProviderData = (API, callback) => {
  useEffect(() => {
    const sessionId = localStorage.getItem('sessionId');
    const userId = localStorage.getItem('userId');
    if (!sessionId && !userId) {
      return;
    }
    getData(`${API}/validate`, {
      'session-id': sessionId,
      'user-id': userId,
    })
      .then(message => {
        const { status, user } = message;
        if (status !== 'ok') {
          return;
        }
        const providerData = new User(user);
        callback(providerData)
      });
  }, []);
};

export const getPatientList = (API, callback, shouldRefresh) => {
  useEffect(() => {
    if (!shouldRefresh) return;
    const sessionId = localStorage.getItem('sessionId');
    const userId = localStorage.getItem('userId');
    getData(`${API}/patient`, {
      'session-id': sessionId,
      'user-id': userId,
    })
      .then(message => {
        const { status, users } = message;
        if (status !== 'ok') {
          return;
        }
        callback(users);
      });
  }, [ shouldRefresh, callback ]);
};

export const searchPatients= (patientList, name, callback) => {
  const [ shouldSearch, setShouldSearch ] = useState(true);
  useEffect(() => {
    if (!name || !shouldSearch) {
      return;
    }
    const foundPatients = patientList.filter(patient => {
      const { firstName, lastName } = patient;
      const patientName = `${firstName} ${lastName}`;
      const nameMatch = new RegExp(name, 'i');
      return nameMatch.test(patientName);
    });
    callback(foundPatients);
    setShouldSearch(false);
    return function throttleSearch() {
      const throttleSpeed = 1200;
      setTimeout(() => {
        setShouldSearch(true);
      }, throttleSpeed);
    }
  }, [ name, shouldSearch, setShouldSearch ]);
};

export const getAppointments = (API, type, criteria, callback) => {
  useEffect(() => {
    const sessionId = localStorage.getItem('sessionId');
    const userId = localStorage.getItem('userId');
    getData(`${API}/appointment/${type}/${criteria}`, {
      'session-id': sessionId,
      'user-id': userId,
    })
      .then(message => {
        const { status, appointments } = message;
        if (status !== 'ok') {
          return;
        }
        callback(appointments);
      });
  }, [ type, criteria, callback ]);
}

export const getParticipants = (API, callback, shouldRefresh) => {
  useEffect(() => {
    if (!shouldRefresh) return;
    const sessionId = localStorage.getItem('sessionId');
    const userId = localStorage.getItem('userId');
    getData(`${API}/participants`, {
      'session-id': sessionId,
      'user-id': userId,
    })
      .then(message => {
        callback(message);
      });
  }, [ shouldRefresh, callback ]);
};
