import React, { useEffect, useRef } from 'react';

function VideoBox() {
  const videoEl = useRef(null);
  const audioEl = useRef(null);

  // ...

  function getVideoComponent() {
    return <video autoPlay muted playsInline ref={videoEl} />;
  }

  function getAudioComponent() {
    return (
      // !props.isLocalPerson &&
      <audio autoPlay playsInline ref={audioEl} />
    );
  }

  // ...

  return (
    <div className={''}>
      <div className="background" />
      // ...
      {getVideoComponent()}
      {getAudioComponent()}
    </div>
  );
}

export default VideoBox;
