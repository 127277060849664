import React, { useState } from 'react';
import PatientBox from './PatientBox';
import PatientInfo from './PatientInfo';
import SearchPatient from './SearchPatient';
import { formatAddress } from '../../helper';
import './style.scss';

function PatientsPanel({ patientList }) {
  const [ patientDetailAtIndex, setPatientDetailAtIndex ] = useState(null);
  const [ foundPatients, setFoundPatients ] = useState([]);
  const listToDisplay = foundPatients.length !== 0 ? foundPatients : patientList;
  const PatientsComp = listToDisplay.map((patient, idx) => (
    <div className="patient-wrapper" key={`patient-wrapper-${idx}`}>
      <PatientBox
        patientId={patient.userId}
        firstName={patient.firstName}
        lastName={patient.lastName}
        setExpandPatientInfo={() => setPatientDetailAtIndex(idx)}
      />
      { patientDetailAtIndex === idx &&
        <PatientInfo
          dob={patient.dob}
          email={patient.email}
          phone={patient.mobile}
          address={formatAddress(
            patient.streetAddressOne,
            patient.streetAddressTwo,
            patient.city,
            patient.state,
            patient.zipCode,
          )}
        />
      }
    </div>
  ));
  return (
    <>
      <div className="patients-header">
        <h2 className="patients-header-title">Patients</h2>
      </div>
      <SearchPatient
        patientList={patientList}
        setFoundPatients={setFoundPatients}
      />
      { PatientsComp }
    </>
  );
}

export default PatientsPanel;