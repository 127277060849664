import React, { useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { postData } from '../../helper';
import { Invite } from '../../models';
import en from '../../locale/en';
import { navigateOnly } from '../../hooks';

import './style.scss';

function InvitePatients({ API, provider }) {
  const navigate = useNavigate();
  const [ patientMobile, setPatientMobile ] = useState('');

  navigateOnly('/', provider === null, { routeFrom: '/invite-patient' });

  const handleInvitePatient = () => {
    const { userId } = provider;
    const invite = new Invite(userId, patientMobile)
    postData(`${API}/invite`, invite)
      .then(message => {
        const { status } = message;
        if (status !== 'ok') {
          navigate(
            '/error',
            { 
              state: {
                statusMessage: en.invitePatients.inviteErrorMessage,
                statusButton: en.invitePatients.inviteErrorButtton,
                buttonRoute: '/overview',
                routeFrom: '/invite-patient',
              },
            }
          );
          return;
        }
        navigate(
          '/success',
          {
            state: {
              statusMessage: en.invitePatients.inviteSuccessMessage,
              statusButton: en.invitePatients.inviteSuccessButtton,
              buttonRoute: '/overview',
              routeFrom: '/invite-patient', 
            },
          }
        );
      });
  };

  return (
    <section className="invite-patient-container">
      <h2 className="invite-patient-title">
        {en.invitePatients.title}
      </h2>
      <Link className="invite-patient-back-btn" to="/">
        {en.invitePatients.backButton}
      </Link>
      <article className="invite-patient-input-wrapper">
        <input
          className="invite-patient-input"
          type="text"
          placeholder="Patient's Phone Number"
          value={patientMobile}
          onChange={(e) => {
            setPatientMobile(e.target.value);
          }}
        />
      </article>
      <div className="invite-patient-btn-group">
        <button
          className="invite-patient-btn"
          onClick={handleInvitePatient}
        >
          {en.invitePatients.inviteButton}
        </button>
      </div>
    </section>
  );
}

export default InvitePatients;
