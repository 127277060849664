import React, { useEffect, useCallback } from 'react';
import { DailyProvider } from '@daily-co/daily-react-hooks';
import DailyIframe from '@daily-co/daily-js';

import { postData, getData } from '../../helper';
// import Call from './Call';
import VideoBox from './VideoBox';

function VideoCall() {
  const callObject = DailyIframe.createCallObject();

  useEffect(() => {
    console.log('VideoCall mounted >>>>>>>');
    if (!callObject) return;
  
    const events = [ 'joined-meeting', 'left-meeting', 'error' ];
  
    function handleNewMeetingState(event) {
      console.log('event: ', event);
      event && logDailyEvent(event);
      switch (callObject.meetingState()) {
        case 'joined-meeting':
          // update component state to a "joined" state...
          break;
        case 'left-meeting':
          callObject.destroy().then(() => {
            // update component state to a "left" state...
          });
          break;
        case 'error':
          // update component state to an "error" state...
          break;
        default:
          break;
      }
    }
  
    // Use initial state
    handleNewMeetingState();
  
    // Listen for changes in state
    for (const event of events) {
      callObject.on(event, handleNewMeetingState);
    }
  
    // Stop listening for changes in state
    return function cleanup() {
      for (const event of events) {
        callObject.off(event, handleNewMeetingState);
      }
    };
  }, [ callObject ]);

  const createCall = useCallback(async () => {
  }, []);

  const startJoiningCall = useCallback(url => {
    const callObject = DailyIframe.createCallObject();
    // update component state to a "joining" state...
    callObject.join({ url });
  }, []);

  const startLeavingCall = useCallback(() => {
    if (!callObject) return;
    // update component state to a "leaving" state...
    callObject.leave();
  }, [callObject]);

  // const enableCallButtons = [STATE_JOINED, STATE_ERROR].includes(appState);
  // const enableStartButton = appState === STATE_IDLE;

  return (
    <>
      <DailyProvider callObject={callObject}>
        <div>is currently speaking.</div>
        <button onClick={createCall}>Create Call</button>
        {/* <Call /> */}
        <VideoBox />
      </DailyProvider>
    </>
  );
}

export default VideoCall;
