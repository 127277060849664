import React from 'react';
import { formatDate } from '../../helper';

import './style.scss';

function Overview({ appointments }) {
  let firstAppointmentDate = '';
  const todaysDate = new Date().getDate();
  const todaysYear = new Date().getFullYear();
  const todaysAppointment = appointments.filter(appointment => {
    const appointmentDate = new Date(appointment.appointmentStartDate);
    return (
      appointmentDate.getDate() === todaysDate
      && appointmentDate.getFullYear() === todaysYear
    );
  });
  if (todaysAppointment.length > 0) {
    firstAppointmentDate = formatDate(todaysAppointment[0].appointmentStartDate);
  }
  return (
    <>
      <div className="overview-header">
        <h2 className="overview-header-title">Overview</h2>
      </div>
      <div className="overview-wrapper">
        <p className="overview-text">
          {`You have ${todaysAppointment.length > 0 ? todaysAppointment.length : 0} appointments today. `}
          {firstAppointmentDate === '' ? '' : `Your next appointment starts at ${firstAppointmentDate}`}
        </p>
      </div>
    </>
  );
}

export default Overview;
